import React from 'react'

import SEO from '../../components/seo'
import PSAWifi from '../../components/PSAWifi/PSAWifi'

const Wifi: React.FC = () => {
  return (
    <div>
      <SEO
        title="Wi-fi Recommended"
        description="Make sure you have a stable internet connection throughout the gameplay"
      />
      <PSAWifi />
    </div>
  )
}

export default Wifi
