import React, { CSSProperties } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import '../ResponsibleGaming/ResponsibleGaming.scss'
import Container from 'react-bootstrap/esm/Container'
import Row from 'react-bootstrap/esm/Row'
import Col from 'react-bootstrap/esm/Col'

const PSAWifi: React.FC = () => {
  const data = useStaticQuery(graphql`
    {
      quote: file(relativePath: { eq: "noun-quote.png" }) {
        childImageSharp {
          gatsbyImageData(width: 174, layout: CONSTRAINED)
        }
      }
      kyc: file(relativePath: { eq: "kyc-verification.png" }) {
        childImageSharp {
          gatsbyImageData(width: 120, layout: CONSTRAINED)
        }
      }
      plus18: file(relativePath: { eq: "18plus.png" }) {
        childImageSharp {
          gatsbyImageData(width: 120, layout: CONSTRAINED)
        }
      }
      aigf: file(relativePath: { eq: "aigf-round.png" }) {
        childImageSharp {
          gatsbyImageData(width: 120, layout: CONSTRAINED)
        }
      }
    }
  `)

  const th: CSSProperties = {
    color: '#000',
    fontSize: '1rem',
    fontWeight: 'bold',
    letterSpacing: 0,
    lineHeight: '19px',
    marginBottom: '4px',
  }

  const td: CSSProperties = {
    fontWeight: 300,
    fontSize: '0.75rem',
  }

  return (
    <div id="responsible-gaming">
      <div className="header">
        <Container>
          <h1>Wi-fi Recommended</h1>
          <GatsbyImage
            image={data.quote.childImageSharp.gatsbyImageData}
            loading="eager"
            alt="quote"
            className="quote-image"
          />
          <div>
            <Row>
              <Col md={8} className="quote">
                <span>Make sure you </span>
                <span>have a stable internet </span>
                <span>connection throughout </span>
                <span>
                  the <span className="underline">gameplay.</span>
                </span>
              </Col>
            </Row>
          </div>
        </Container>
      </div>

      <div className="steps">
        <Container>
          <div style={th}>40 ms</div>
          <div style={td}>Recommended ping rate</div>

          <div style={{ ...th, marginTop: '32px' }}>1.5 Mbps</div>
          <div style={td}>Recommended internet speed</div>
        </Container>
      </div>

      <div className="user-responsibilities">
        <Container>
          <h2 style={{ lineHeight: '34px' }}>
            What happens if I get disconnected in cash format?
          </h2>
          <div className="text">
            In Cash format, if you are disconnected, after you are timed out,
            you will be put into away mode for 120 seconds, where default
            actions will be auto-executed. If you do not return with a stable
            connection within 120 seconds, you will be shifted to the spectate
            mode
          </div>
        </Container>
      </div>

      <div className="user-responsibilities">
        <Container>
          <h2 style={{ lineHeight: '34px' }}>
            What happens if I get disconnected in MTT format?
          </h2>
          <div className="text">
            In MTT, if you are disconnected, after you are timed out, you will
            be put into away mode, where default actions will be auto-executed.
            To return to the gameplay you need to click on the ‘I’m back’ button
          </div>
        </Container>
      </div>
    </div>
  )
}

export default PSAWifi
